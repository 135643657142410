<div class="faq">


<div class="row">
    <div class="col-12">

        <div class="faq__accordion-header">
            <h1 class="faq__accordion-header-title">Frequently Asked Questions</h1>
            <button id="faq-expandCollapseToggle" class="btn faq__accordion-header-button" (click)="expandCollapseAllToggle()">{{expandCollapseLabel}}</button>
        </div>

        <ngb-accordion #accordion="ngbAccordion">
            <ngb-panel id="toggle-1">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-1')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        What is 2020source?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    2020source is a secure website that allows approved
                    providers to do business with VSP. When an account is established, users can file and save claims, view
                    claim history, search for claims based on several search criteria, and update their user profile.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-2">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-2')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Should I use 2020source?</button></div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Filing claims electronically through 2020source
                    provides a quicker and more efficient submission, resulting in faster reimbursements.
                </ng-template>
            </ngb-panel>


            <ngb-panel id="toggle-3" *ngIf="isLoggedIn()">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-3')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How do I add other users to my account?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    If you would like to add an authorized user please email
                    <a appMailTo [subject]="'Authorized User Request Account ' + accountNumber()" [body]="'User Name:%0D%0AEmail Address:'"></a>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-4" *ngIf="isLoggedIn()">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-4')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How do I remove a user from my account?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    You can view the list of Authorized
                    Users by clicking on the "Authorized Users" link in "Your Account." Click on the pencil icon, and then
                    change the status from "active" to "inactive" and save using the icon. The user will receive an email
                    notifying them that their account has been deactivated. Please be aware that the user name will be
                    removed from your list of authorized users and if they need to be reactivated, you will need to email
                    <a appMailTo [subject]="'Account ' + accountNumber()"></a>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-5">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-5')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Can I change my account profile?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    You can update or change your user information in your account profile at any time.
                    Simply click on "Your Account" and modify your information. Be sure to save your changes.
                    You will receive an email confirming your account information when you change the email address or password.
                    If you need to update your office, practice, or billing information, please email <a appMailTo [subject]="'Account ' + accountNumber()"></a>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-6">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-6')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        What if I've forgotten my password?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    From the logon page, click on "Forgot Your
                    Password?" Complete the information requested and click "Submit." You will receive an email with a link
                    that will direct you to a screen where you can choose a new password.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-7">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-7')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Can I change my password?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Yes, you can change your password at any time. Click
                    on "Your Account," then click on "Password."
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-8">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-8')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Can I change my email address?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Go to "Your Account" and re-enter a new email
                    address. Click on "Save Changes." You will receive an email confirming your new email address.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-9">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-9')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How do I submit a claim?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Click on "Claims" in the navigation bar at the top.
                Then click “Claim Form.” Enter your Authorization number and the Date of Service to access the claim form.
                Complete all required fields in the form and click on "Submit."
                You also have the option to save the claim for later use or to reset the form to its last saved state.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-10">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-10')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Can I resubmit a claim?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    You can't resubmit a claim on 2020source. If you
                have questions or concerns with a claim, email
                <a appMailTo [subject]="'Account ' + accountNumber()"></a>
                for help. If you are advised to submit another claim via 2020source, you will need a new Authorization Number.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-11">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-11')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Is there a fee to file my claims?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    No. At this time, filing claims on
                    2020source is free of charge.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-12">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-12')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How do I access my claim history on 2020source?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Click on "Claims" in the navigation bar at the top.
                    There will be a dropdown menu to search by Claim Status, Date, or Last Name.
                    Click on the desired search criteria, enter the required information, and click Search.
                    You will now be able to view a copy of the claim.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-13">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-13')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How long will my claims be available in the claim history?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    You can access claims that are up to 13 months old in the claim history.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-14">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-14')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Who do I contact if I have questions filling out my claim form or using 2020source?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Email <a appMailTo></a>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-15">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-15')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Who do I contact if I have questions about insurance coverage or reimbursement?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    For additional information or insurance related questions, email <a appMailTo></a>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-16">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-16')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Why does the system take me back to the login screen?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    To protect private
                    health information, you will be automatically logged out after a half hour, if you have not actively
                    continued using the site. The system will reset and require you to log in again. If you have a claim
                    open that you have not saved, you will lose the information you entered. Always save your claim before a
                    period of inactivity on the site.

                </ng-template>
            </ngb-panel>



            <ngb-panel id="toggle-18">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-18')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        Is 2020source HIPAA compliant?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    2020source is fully HIPAA compliant. Please review the HIPAA privacy policy provided on
                <a href="https://www.vsp.com/privacy.html">VSP.com</a>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-19">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-19')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        What if I've forgotten my Account Number?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    If you have forgotten your Account Number, you will need to email <a appMailTo></a>
                    and provide your email address.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-20">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-20')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        What is an &quot;Insured ID Number&quot; or a &quot;Member ID Number?&quot;</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    This is the ID number assigned by VSP to the person that is the main subscriber on the insurance policy.
                If your patient is not the VSP insured member (a spouse, child, etc.), please use the ID number of the
                person listed as the VSP member when asked for either the "Insured ID Number" or the "Member ID
                Number."
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-21">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-21')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        What are the CPT/HCPCS codes I need to use on the claim form?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    The Healthcare Common Procedure Coding System (HCPCS) is a set of health care procedure codes based on
                    the American Medical Association's Current Procedural Terminology (CPT). These codes are used for the
                    services and materials you provide to your patients. Their use is legally required to ensure consistent
                    insurance claims processing. You can order CPT and HCPCS code books by calling <strong>800.MED.SHOP</strong>.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-22">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-22')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How do I find a claim I saved to complete later?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Click on "Claims" in the navigation bar at the top.
                    There will be a dropdown menu to search by Claim Status, Date, or Last Name.
                    Click on the desired search criteria, enter the required information, and click Search.
                    You will now be able to view a copy of the claim.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-23">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-23')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How long will it take VSP to process my claim and send my reimbursement?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  Claims are normally processed within five business days from the date VSP receives the claim. You will receive your reimbursement according to our normal payment cycle.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-24">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-24')}"></i>
                        </div>
                        <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                            When I'm filling out a claim form on 2020source, can I use the word &quot;SAME&quot; instead of repeating patient and address information?</button>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    No. Using the word "SAME" in any field will result in claim processing errors. You must complete each required field with accurate information.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-25">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-25')}"></i>
                        </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        How do I check my VSP patient's benefits online?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Always check your VSP patients’ eligibility and coverage before providing services to the patient.
                    Once you have logged in to your account, simply click on "Patient Search" and complete the required fields.
                </ng-template>
            </ngb-panel>

            <ngb-panel id="toggle-26">
                <ng-template ngbPanelHeader>
                    <div class="faq__headerWrapper">
                        <div class="faq__headerWrapper-icon">
                            <i class="vspicon-vsp_arrow_up faq__icon" [ngClass]="{'vspicon-vsp_arrow_down': accordion.isExpanded('toggle-26')}"></i>
                            </div>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left">
                        What is a &quot;coordination of benefit claim&quot; and how do I note this on the claim form to VSP?</button>
                        </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    Your VSP patient may ask you to coordinate their claim with another health benefit plan. This means that
                    the patient has additional vision coverage by VSP or another carrier, and wishes to use plan benefits
                    from both plans. Please click on the check box in field 11d on the Claim Form. Box 9 fields will then
                    automatically display on the form. Complete each Box 9 field.
                </ng-template>
            </ngb-panel>





        </ngb-accordion>
    </div>

</div>
</div>
