export class PatientEligibilityReportConstants {
  // used to filter sublabels on patient eligibility report
  // ex. public static EXCLUDE_VERBALIZATION_BY_SUB_LABEL = ['CL Exam Services'];
  public static EXCLUDE_VERBALIZATION_BY_SUB_LABEL = [];
  public static PLAN_DETAILS_LINE_ORDER: PlanDetailOrder[] = [
    {
      includes: 'co-payments'
    }, {
      includes: 'exam'
    }, {
      includes: 'lens',
      excludes: 'contact'
    }, {
      includes: 'frame'
    }, {
      includes: 'contact'
    }, {
      includes: 'value added benefits'
    }
  ];

}

export interface PlanDetailOrder {
  includes: string;
  excludes?: string;
}
