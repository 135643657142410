
import {ErrorCode} from '@app/core/models/error-code.inteface';

export class IApiError {
    constructor(
        public status?: string,
        public statusDesc?: string,
        public code?: string,
        public message?: string,
        public userMessage?: string,
        public description?: string,
        public error?: string,
        public details?: ErrorCode[]
    ) { }
}
