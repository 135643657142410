export class PatientEncounterConstants {
  public static getBasePatientEncounterUrl = 'patient-encounter-api/patientencounter';
  public static getBasePatientEncounterExtendedUrl = 'patient-encounter-api/patientencounter/extended';
  public static getBasePatientExpenseCalculatorUrl = 'patient-encounter-api/patientencounter/patientexpensecalculator';
  public static getLegacyPatientEncounterUrl = 'patient-encounter-api/patientencounter/claims';
  public static getClaimSummaryUrl = 'doctor-claim-history-report-api/report';

  public static message11 = 'This authorization number is not valid or has been deleted.';
  public static message11addl = 'Please obtain a new Authorization. Message #11';
  public static message12 = 'The Authorization Number you have entered is not valid for this date of service.';
  public static message12addl = 'Please check the date or obtain a new authorization. Message #12';
  public static message15 = 'The Authorization Number you have entered cannot be used by your office.';
  public static message15addl = 'Please have the member contact VSP directly for further assistance. Message #15';
  public static otherAdd = 'Please contact apquestions@vsp.com for further assistance.';

}


// https://api-integration.vspglobal.com/patient-encounter-api/
// patientencounter?startDate=2019-12-29&endDate=2020-02-27&
// pageSize=25&pageNumber=0&sortBy=SAVE_SUBMIT_DATE&sortDirection=DESC&t=1582814594206
