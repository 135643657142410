import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ISessionService } from '@app/core/session';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { IIdentityService } from '@app/core/providers';
import { IUserProfile } from '@app/core';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  public constructor(
    private readonly authenticationService: AuthenticationService,
    sessionService: ISessionService,
    private readonly identityService: IIdentityService,
    private readonly identityProvider: IdentityProvider,
    private readonly router: Router
    ) {
      sessionService.init();
  }

  canActivate(): Observable<boolean> {
    return new Observable((observer) => {
      this.authenticationService.isSessionActive().subscribe((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['sign-in']);
        } else {
          this.identityService.getUserProfile().subscribe((userInfoResponse: IUserProfile) => {
            this.identityProvider.setCurrentUser({ ...this.authenticationService.user, userProfile: userInfoResponse });
          });
        }
        observer.next(isAuthenticated);
        observer.complete();
      });
    });
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }
}
