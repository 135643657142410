import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { PrivateRoutingModule } from './private-routing.module';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '@app/shared/components/datepicker-popup/custom-date-parser-formatter.service';
import { CostcoClaimSummaryComponent } from '@app/private/costco-claim-summary/costco-claim-summary.component';
import { ClaimSummaryHeaderComponent } from '@app/private/costco-claim-summary/claim-summary-header/claim-summary-header.component';
import { ClaimSummaryBodyComponent } from './costco-claim-summary/claim-summary-body/claim-summary-body.component';
import { PdfViewerComponent } from '@app/private/pdf-viewer/pdf-viewer.component';
import { NgxBarcode6Module } from 'ngx-barcode6';



@NgModule({
  declarations: [
    HomeComponent,
    CostcoClaimSummaryComponent,
    ClaimSummaryHeaderComponent,
    ClaimSummaryBodyComponent,
    PdfViewerComponent
  ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    SharedModule,
    NgxBarcode6Module
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})
export class PrivateModule { }
