import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appMailTo]'
})
export class MailToDirective implements OnInit {
  private defaultAddressee = 'apquestions@vsp.com';
  private defaultSubject = '2020source Pilot';

  @Input() addressee: string;
  @Input() subject: string;
  @Input() body: string;

  constructor(private el: ElementRef) { }

  public ngOnInit(): void {
    this.addMailToHref();
  }

  private addMailToHref(): void {
    const addressee = this.addressee ? this.addressee : this.defaultAddressee;
    const subject = this.defaultSubject + (this.subject ? ' ' + this.subject : '');

    this.el.nativeElement.href = `mailto:${addressee}?Subject=${subject}${this.body ? `&body=${this.body}` : ''}`;
    this.el.nativeElement.innerHTML = addressee;
  }
}
