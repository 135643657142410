<div class="costco-claim-summary">
  <app-claim-summary-header [claim]="claim"></app-claim-summary-header>
  <app-claim-summary-body [claim]="claim"></app-claim-summary-body>
  <div class="footer">
    <div class="row">
      <small>
        <span class="footer__text--red">Costco Internal Use Only</span>
      </small>
    </div>
    <br>
    <div class="row">
      <small>
        <span class="footer__text--red">This information does not guarantee patient eligibility, patient coverage, or payments to providers. Confirmation of eligibility will be determined upon receipt of the claim by VSP.</span>
      </small>
    </div>
    <br>
    <div class="row">
      <span>If your patient has Cigna and you have questions about the coverage on this report, please call Cigna Vision at 877.477.7557.</span>
    </div>
    <br>
    <div class="row">
      <small>
        <span>VSP is a registered trademark of Vision Service Plan. </span>
      </small>
    </div>
  </div>
  <app-sticky-button [iconClass]="'vspicon-vsp_print'"></app-sticky-button>
</div>
