import { IUser } from '@app/core/authentication';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentityProvider {

  /* tslint:disable:variable-name */
  private _currentUser: IUser;
  isCurrentUserSet = new BehaviorSubject<boolean>(undefined);

  public get currentUser() {
    return Object.assign({}, this._currentUser);
  }
  constructor() {
  }
  public setCurrentUser(user: IUser): void {
    this._currentUser = { ...user };
    this.isCurrentUserSet.next(true);
  }

  public hasClaimFormAccess(): boolean {
    return this._currentUser.userProfile.hasClaimForm === 'Y';
  }

  public isCCDispensary(): boolean {
    const ccUserCodes = ['COSTA', 'COSTC', 'COSTQ'];

    return this._currentUser.userProfile.networkIds.some(network => ccUserCodes.indexOf(network) > -1)
      && this._currentUser.userProfile.serviceType === 'DL';

  }
}
