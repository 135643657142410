import { environment } from 'src/environments';
import { IUser } from '@app/core/authentication';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent, Observable, ReplaySubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private iFrame: HTMLIFrameElement;
  private _loggedIn = false;
  private renderer: Renderer2;
  public params: string;
  public user: IUser = { isAuthenticated: false, token: '' };
  public isAuthenticated = new ReplaySubject<boolean>(1);

  constructor(
    private readonly rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.params = this.createParams();
  }

  public set loggedIn(loggedIn: boolean) {
    this._loggedIn = loggedIn;
  }

  public get loggedIn(): boolean {
    return this._loggedIn;
  }

  public login() {
    window.open(`${environment.authConfig.issuer}/acs2/login?${this.params}`, '_parent');
  }

  public logout() {
    this.iFrame.contentWindow.postMessage(JSON.stringify({ type: 'logout' }), '*');
    this.user.token = '';
    this.user.isAuthenticated = false;

  }

  private createParams() {
    const httpParams = new HttpParams()
      .append('client_id', environment.authConfig.clientId)
      .append('scope', environment.authConfig.scopes.join(' '))
      .append('redirect_uri', environment.authConfig.redirectUri);
    if (environment.authConfig.errorRedirectUri) {
      httpParams.append('error_redirect_uri', environment.authConfig.errorRedirectUri);
    }
    return httpParams.toString();
  }

  private setUserData(tokenWithType: string) {
    this.user = {
      ...this.user,
      isAuthenticated: Boolean(tokenWithType),
      token: tokenWithType
    };
    if (tokenWithType) {
      const token = tokenWithType.split(' ')[1];
      const encodedUserData = token.split('.')[1];
      this.user.userData = JSON.parse(atob(encodedUserData));
    }
  }

  /**
   * Called by the AuthGuard to determine if the session is active for all secure pages. If true is returned then the
   * user is logged in, false if the user is not logged in.
   * @returns Observable<boolean>
   */
  isSessionActive(): Observable<boolean> {
    return new Observable((observer) => {
      // resolve with true if there is a valid ACS session
      if (this.loggedIn) {
        observer.next(true);
        observer.complete();
      } else {
        // Initialize ACS iFrame and check for token, token means they are logged in and session is active.
        this.initAuthenticationService().subscribe((acsActive: boolean) => {
          observer.next(acsActive);
          observer.complete();
        });
      }
    });
  }

  /**
   * This method will instantiate the iframe for ACS if it doesn't already exist and also start watching for tokens
   * being sent to the UI from the ACS iframe. The subscription to watching the iframe messages is not closed, but the
   * new Observable instantiated and returned by this method is completed so that the response observable is closed.
   * @returns Observable<boolean>
   */
  private initAuthenticationService(): Observable<boolean> {
    return new Observable((observer) => {
      fromEvent(window, 'message')
        .pipe(
          filter(({ origin }: Partial<MessageEvent> = { origin: '' }) => origin.includes(environment.acsUrl)),
          map((event) => event.data)
        ).subscribe((token: string) => {
          if (token) {
              this.setUserData(token);
              this.isAuthenticated.next(Boolean(token));
              this.loggedIn = true;
              observer.next(true);
              observer.complete();
          } else {
              observer.next(false);
              observer.complete();
          }
      });
      this.iFrame = this.renderer.createElement('iframe');
      this.iFrame.src = `${environment.acsUrl}/acs2/iframe?${this.createParams()}`;
      this.iFrame.style.display = 'none';
      this.renderer.appendChild(document.body, this.iFrame);
    });
  }
}
