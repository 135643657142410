export class ErrorMessages {
    public static GENERIC_ERROR_MESSAGE = 'A problem was encountered processing your request, please try again. ' +
    'If you are still experiencing a problem, check back at a later time.';
    public static MET_CIGNA_LIFE_ERROR_MESSAGE = 'The benefit plan for member ';
    public static METLIFE_ERROR_MESSAGE_CONT =  ' cannot be verified. ' +
    'Please call MetLife for further assistance. Message #5';
    public static CIGNA_LIFE_ERROR_MESSAGE_CONT =  ' cannot be verified. ' +
    'Please call Cigna Vision for further assistance. Message #7';
    public static NO_DEPENDENTS_ERROR_MESSAGE = 'No dependents were found. Please have the member contact VSP for ' +
      'further assistance. Message #19';
    public static NO_MEMBER_FOUND_ERROR_MESSAGE = 'The benefit plan cannot be verified. ' +
    'Please verify that the correct criteria has been entered. ' +
    'If error persists please have the member contact VSP for further assistance. Message #16';
    public static EMPTY_REQUIRED_FIELDS = 'Please enter required fields';
    public static INVALID_PASSWORD = 'Your password does not meet the requirements. Please choose a new one.';
    public static INVALID_OLD_DATE = 'Date of Service is greater than 6 months old. Please ' +
      '<a href="contact-us">call VSP</a>' +
      ' to verify your VSP patient’s out-of-network eligibility and allowances.';
    public static PRIOR_DATE = 'Cannot be prior to the From date';
    public static GENERIC_DCHR_MESSAGE = 'Unable to retrieve Claim Summary Report at this time, try again later.';

    public static INVALID_FUTURE_DATE = (value: string) => `${value} is a date in the future`;
    public static INVALID_DATE_FORMAT = (value: string) => `${value} is not a properly formatted date (mm/dd/yyyy)`;
    public static INVALID_EMAIL = (value: string) => `${value} is not a valid email address`;
    public static INVALID_PAST_DATE = (value: string) => `Date must be after ${value}`;
}
