export function getSavedSubmitDate(lastDoctorUpdatedDateTime: string) {
  if (lastDoctorUpdatedDateTime) {
    // split the ISO date by "T" to get the date part only
    const dateString = lastDoctorUpdatedDateTime.split('T')[0];
    // convert the date string from YYYY-MM-DD to MM-DD-YYYY format
    const datePieces = dateString.split('-');
    const yyyy = datePieces[0];
    const mm = datePieces[1];
    const dd = datePieces[2];

    return [mm, dd, yyyy].join('/');
  } else {
    // return an empty string if there is no lastDoctorUpdatedDateTime property on the claim
    return '';
  }
}

export function get13MonthsAgo(): object {
  const months13ago = new Date();
  months13ago.setMonth(months13ago.getMonth() - 13);
  return {
    year: months13ago.getFullYear(),
    month: months13ago.getMonth() + 1,
    day: months13ago.getDate()
  };
}

export function getMaxDateForDatePicker(date: string): object {
  const dateSplit = date.split('-');
  return {
    year: Number(dateSplit[0]),
    month: Number(dateSplit[1]),
    day: Number(dateSplit[2])
  };
}

export function convertDateToStringYYYYMMDD(date: Date): string {
  const dd = date.getDate();
  const mm = date.getMonth() + 1; // Months are 0-based
  const yyyy = date.getFullYear();
  let day = dd.toString();
  let month = mm.toString();
  // add zeros before month and day if less than 10
  if (dd < 10) {
    day = '0' + dd;
  }
  if (mm < 10) {
    month = '0' + mm;
  }

  return [yyyy, month, day].join('-');
}

export function convertStringMMDDYYYYToDate(date: string): Date {
  const dateSplit = date.split('-');
  return new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, Number(dateSplit[2]));
}

export function convertDateStringForClaimHistory(date: string): string {
  const dateSplit = date.split('/');
  return [dateSplit[2], dateSplit[0], dateSplit[1]].join('-');
}

export function convertDateStringToMMDDYYYYbySlash(date: string): string {
  const dateSplit = date.split('-');
  return [dateSplit[1], dateSplit[2], dateSplit[0]].join('/');
}

export function convertDateStringToYYYYMMDDbyDash(date: string): string {
  if (date != null && date !== 'undefined' && date.indexOf('-') < 0 ) {
    const dateSplit = date.split('/');
    return [dateSplit[2], dateSplit[0], dateSplit[1]].join('-');
  }
  return date;
}
export function formatPageSummaryGenDate(): string {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' };
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const now = new Date();
  const day = weekdays[now.getDay()];

  return (day + ' ' + now.toLocaleString('en-US', options).replace(',', ''));
}
