import { IFormMapper } from './form-mapper.inteface';
import { FormGroup, FormControl, AbstractControl, FormArray, AbstractControlOptions } from '@angular/forms';

export class FormMapper implements IFormMapper {
    public createGroup<TItem>(item: TItem, options?: AbstractControlOptions): FormGroup {
        const result = new FormGroup({}, options);
        for (const [key, value] of Object.entries(item)) {
            result.addControl(key, this.createControlFor(value));
        }

        return result;
    }

    public appendToGroup<TItem>(group: FormGroup, item: TItem): void {
        for (const [key, value] of Object.entries(item)) {
            group.addControl(key, this.createControlFor(value));
        }
    }

    public createControl<TItem>(item: TItem): FormControl {
        return new FormControl(item);
    }

    public createArray<TItem>(items: TItem[]): FormArray {
        return new FormArray(items.map(v => this.createControlFor(v)));
    }

    public setFormControlFromModel(form: AbstractControl, model: any, parentKey?: string) {
        if (!model) {
            return;
        }
        Object.keys(model).forEach(k => {
            const key = parentKey ? `${parentKey}.${k}` : k;
            if (this.isPrimitive(model[k])) {
                const controlToSet = form.get(key);
                if (controlToSet) {
                    controlToSet.setValue(model[k]);
                }
                return;
            }
            this.setFormControlFromModel(form, model[k], key);
        });
    }
    private createControlFor<TItem>(item: TItem): AbstractControl {
        let result: AbstractControl;
        if (Array.isArray(item)) {
            result = this.createArray(item);
        } else if (this.isPrimitive(item)) {
            result = this.createControl(item);
        } else {
            return this.createGroup(item);
        }

        return result;
    }

    private isPrimitive<TValue>(value: TValue): boolean {
        return (value instanceof Date) || (value !== Object(value));
    }

}
