import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef } from '@angular/core';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

  @ViewChild('accordion', {static : true}) accordion: NgbAccordion;
  @ViewChildren(NgbPanel) sections: QueryList<NgbPanel>;

  constructor(
    private elem: ElementRef,
    private readonly identity: IdentityProvider) { }

  expandCollapseLabel = 'Expand All';
  isAllExpanded = false;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.sections.changes.subscribe(ql => {
      (ql as QueryList<NgbPanel>)
      .forEach(i => {
        this.panelToggleClass(i);
      });
    });
  }

  expandCollapseAllToggle() {
    if (this.isAllExpanded) {
      this.accordion.collapseAll();
      this.expandCollapseLabel = 'Expand All';
    } else {
      this.accordion.expandAll();
      this.expandCollapseLabel = 'Collapse All';
    }
    this.isAllExpanded = !this.isAllExpanded;
  }

  panelToggleClass(panel: NgbPanel) {
    if (panel.isOpen) {
      this.elem.nativeElement.querySelector(`#${panel.id}-header`).classList.add('active');
    } else {
      this.elem.nativeElement.querySelector(`#${panel.id}-header`).classList.remove('active');
    }
  }

  isLoggedIn(): boolean {
    return !!this.identity && this.identity.currentUser.isAuthenticated;
  }

  accountNumber(): string {
    return (!!this.identity && this.identity.currentUser.userProfile) ? this.identity.currentUser.userProfile.accountNumber : '';
  }
}
