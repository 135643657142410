import { Injectable } from '@angular/core';
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from 'src/environments';
import { ISessionService } from '@app/core/session/session.service.interface';
import { ISessionConfig } from '@app/core/session/session-config.interface';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionTimeoutComponent } from '@app/shared/components/session-timeout/session-timeout.component';
import { Router } from '@angular/router';
import { IAlertService } from '@app/core/services/alert/alert.service.interface';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements ISessionService {
  private isInitiated = false;
  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    size: 'md',
  };

  public get sessionConfig(): ISessionConfig {
    return environment.sessionConfig;
  }

  constructor(
    private readonly idle: Idle,
    private readonly modalService: NgbModal,
    private readonly authenticationService: AuthenticationService,
    private readonly alertService: IAlertService,
    private readonly router: Router,
  ) { }

  private onAuthenticationChange(isAuthenticated: boolean): void {
    if (isAuthenticated) {
      this.isInitiated ? this.restartSession() : this.startSession();
    } else {
      this.router.navigate(['sign-in']);
      this.stopSession();
    }
  }

  public init() {
    this.authenticationService.isAuthenticated
      .subscribe((isAuthenticated: boolean) => this.onAuthenticationChange(isAuthenticated));
  }

  public startSession(): void {
    this.setupNgIdle();

    this.idle.onIdleStart.subscribe(() => this.openModal());
    this.idle.onTimeoutWarning.subscribe((countdown: number) => this.setCountdown(countdown));
    this.idle.onTimeout.subscribe(() => this.timeoutUser());

    this.isInitiated = true;

    this.restartSession();
  }

  private setupNgIdle(): void {
    this.idle.setIdle(this.sessionConfig.idle);
    this.idle.setTimeout(this.sessionConfig.timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setAutoResume(AutoResume.notIdle);
  }

  private openModal(): void {
    this.modalRef = this.modalService.open(SessionTimeoutComponent, this.modalOptions);
    this.modalRef.componentInstance.logout.subscribe(() => this.logoutUser());
  }

  private setCountdown(countdown: number): void {
    if (this.modalRef && this.modalRef.componentInstance) {
      this.modalRef.componentInstance.countdown = countdown;
    } else {
      this.restartSession();
    }
  }

  private logoutUser(): void {
    this.modalRef.close();
    this.authenticationService.logout();
    this.stopSession();
  }

  private timeoutUser(): void {
    this.logoutUser();
    const idleTimeInMinutes = this.convertSecondsToMinutes(this.sessionConfig.idle);
    this.router.navigate(['sign-in']).then(() =>
      this.alertService.addWarning(`Your session has exceeded ${idleTimeInMinutes} minutes of inactivity. Please log in again.`)
    );
  }

  private convertSecondsToMinutes(time: number): number {
    return time / 60;
  }

  public stopSession(): void {
    this.idle.stop();
  }

  public restartSession(): void {
    this.idle.watch();
  }
}
