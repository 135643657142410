import { HttpErrorInterceptor } from './error-handling/http-error.interceptor';
import { ISessionService } from '@app/core/session/session.service.interface';
import { SessionService } from '@app/core/session/session.service';
import { IHttpClient } from '@app/core/services/http/http-client.interface';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { throwIfAlreadyLoaded } from '@app/core/guards/module-import/module-import.guard';
import { IAlertService } from '@app/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IFormMapper } from './form-helpers/form-mapper.inteface';
import { FormMapper } from './form-helpers/form-mapper';
import { AlertService } from './services/alert/alert.service';
import { IIdentityService } from './providers/identity/identity-service.interface';
import { IdentityService } from './providers/identity/identity.service';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: IHttpClient, useExisting: HttpClient },
        { provide: IIdentityService, useClass: IdentityService },
        { provide: IFormMapper, useClass: FormMapper },
        { provide: ISessionService, useClass: SessionService },
        { provide: IAlertService, useClass: AlertService },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
      ]
    };
  }
}
