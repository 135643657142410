import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
import { SignInComponent, TermsConditionsComponent, SamlErrorComponent } from '.';

const routes: Routes = [
    {
        path: 'password',
        loadChildren: () => import('./password/password.module')
            .then(m => m.PasswordModule)
    },
    {
        path: 'sign-in',
        component: SignInComponent
    },
    {
        path: 'terms-conditions',
        component: TermsConditionsComponent
    },
    {
        path: 'saml-error',
        component: SamlErrorComponent
    },
    {
        path: 'saml-data-error',
        component: SamlErrorComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class PublicRoutingModule { }
