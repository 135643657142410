import { HomeComponent } from './private/home/home.component';
import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';
import { FaqComponent } from './shared/pages/faq/faq.component';
import { ContactUsComponent } from './shared/pages/contact-us/contact-us.component';
const routes: Routes = [
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
