import { Component, OnInit } from '@angular/core';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';
import {environment} from '../../../../environments';

// @ts-ignore
const data = require('../../../../../package.json');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private readonly identity: IdentityProvider) { }

  showBuildNumbers: boolean = environment.showBuildNumbers;
  buildNumber: string = data.version;
  currentYear = new Date().getFullYear();

  ngOnInit() {

  }

  isLoggedIn(): boolean {
    return this.identity.currentUser.isAuthenticated;
  }


}
