import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IApiError } from '../models/api-error.interface';
import { IAlertService } from '../services/alert/alert.service.interface';
import { PatientEncounterConstants } from '@app/constants/patient-encounter.constants';
import { IPEError } from '@app/core/models/pe-error.interface';
import { ErrorMessages } from '@app/core/Validation/error-messages.constants';
import {IdentityProvider} from '@app/core/providers/identity/identity-provider';
import {PatientService} from '@app/private/patient/services/patient.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
      private readonly alertService: IAlertService,
      private readonly identity: IdentityProvider,
      private readonly patientService: PatientService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        this.handleError()
    );
  }

  private handleError<TResult>() {
    return catchError<TResult, Observable<never>>((res) => {
      this.alertService.clearAlerts();
      if (res instanceof HttpErrorResponse && (res.status === 400 || res.status === 401) && res.error && this.isApiError(res.error)) {
        const apiError = res.error as IApiError;
        this.constructAlertMessageBasedOnResponseCodes(apiError, this.patientService);
      } else if (res instanceof HttpErrorResponse && res.error && this.isPEError(res.error)) {
        const apiError = this.parsePEErrorResponse(res.error.description);
        this.alertService.addDanger(apiError);
      } else if (res instanceof HttpErrorResponse && res.url.includes('doctor-claim-history-report-api/report')) {
        this.alertService.addDanger(ErrorMessages.GENERIC_DCHR_MESSAGE);
      } else if (res.url.includes('retail-web/retail-account-requests/messages') ||
          res.url.includes('retail-web/retail-account-requests/manuals')) {
        return throwError(res);
      } else {
        this.constructAlertMessageBasedOnResponseCodes(res.error, this.patientService);
      }
      return EMPTY;
    });
  }

  private responseHasDependentNotFoundErrorCode(apiDetail) {
    if (apiDetail.code === 'SRVPAT0010') {
      return true;
    }
    return false;
  }

  private responseHasMemberNotFoundErrorCode(apiDetail) {
    if (apiDetail.code === 'SRVMBR0016') {
      return true;
    }
    return false;
  }

  // tslint:disable-next-line:max-line-length
  private constructAlertMessageBasedOnResponseCodes(apiError: any, patientService: PatientService) {
    if (apiError && apiError.userMessage) {
      if (apiError.userMessage.includes('A HREF')) {
        const plaintext =  new DOMParser().parseFromString(apiError.userMessage, 'text/html').body.innerText;
        const splitString = plaintext.split('.');
        this.alertService.addAlertDanger(splitString[0].concat('.'), splitString[1].concat('.'), splitString[2]);
      } else {
        this.alertService.addDanger(apiError.userMessage);
      }

    } else if (apiError && apiError.details && apiError.details.length > 0) {
      if (this.responseHasDependentNotFoundErrorCode(apiError.details[0])) {
        patientService.getRetailEligibilityAPIError().subscribe(response => {
          return EMPTY;
        });
      // Per RETAIL-275 - This was moved here to address the issue that walmart was reporting when no error message was being displayed.
      // Also moving this check inside this method will fix the issue seen in the console error.
      } else if (this.responseHasMemberNotFoundErrorCode(apiError.details[0])) {
        this.alertService.addDanger(ErrorMessages.NO_MEMBER_FOUND_ERROR_MESSAGE);
      // Added for RETAIL-275 to handle a generic error message if the above error codes are not matched.
      } else {
        this.alertService.addDanger(ErrorMessages.GENERIC_ERROR_MESSAGE);
      }
    } else {
      this.alertService.addDanger(ErrorMessages.GENERIC_ERROR_MESSAGE);
    }
  }

  private isApiError(arg: any) {
    const instance = new IApiError();
    return Object.keys(instance).every(k => Object.keys(arg).some(l => l === k));
  }

  private isPEError(arg: any) {
    const instance = new IPEError();
    return Object.keys(instance).every(k => Object.keys(arg).some(l => l === k));
  }

  private parsePEErrorResponse(description) {
    let error = '';
    if (description.indexOf(PatientEncounterConstants.message11) !== -1) {
      if (description.indexOf(PatientEncounterConstants.message11addl) === -1) {
        error = [description, PatientEncounterConstants.message11addl].join(' ');
      }
    } else if (description.indexOf(PatientEncounterConstants.message12) !== -1) {
      error = [description, PatientEncounterConstants.message12addl].join(' ');
    } else if (description.indexOf(PatientEncounterConstants.message15) !== -1) {
      error = [description, PatientEncounterConstants.message15addl].join(' ');
    } else {
      error = [description, PatientEncounterConstants.otherAdd].join(' ');
    }

    return error;
  }
}
