import { SignInComponent } from './sign-in/sign-in.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SharedModule } from '@app/shared/shared.module';
import { PublicRoutingModule } from './public-routing.module';
import { SamlErrorComponent } from './saml-error/saml-error.component';



@NgModule({
  declarations: [
    SignInComponent,
    TermsConditionsComponent,
    SamlErrorComponent
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule
  ],
  exports: [
    SignInComponent
  ]
})
export class PublicModule { }
