import {IEnvironment} from './environment.interface';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: IEnvironment = {
  envName: 'staging',
  dynatraceUrl: 'https://js-cdn.dynatrace.com/jstag/147f273fa2a/bf63206omp/34b0ec24fb6a22c4_complete.js',
  production: false,
  apiPath: 'https://api-staging.vspglobal.com',
  acsUrl: 'https://api-staging.eyefinity.com',
  showBuildNumbers: true,
  authConfig: {
    issuer: 'https://api-staging.eyefinity.com',
    clientId: 'vsp-retail',
    scopes: [
      'auth_retail',
      'openid',
      'profile',
      'read:retail',
      'write:retail',
      'member_view',
      'provider_view',
      'claim_view',
      'eligibility_view',
      'write:claim',
      'write:eligibility',
      'client_view',
      'read:vc.vsr',
      'write:vc.vsr',
      'product_view',
      'read:vc.patientencounter',
      'write:vc.patientencounter',
      'read:vc.affiliatespectaclelens',
      'read:vc.eligibility.vsr_link',
      'read:vc.eligibility.service_type',
      'read:vc.doctorclaimreport',
      'read:vc.eligibility.additional_auth_override',
      'write:vc.vsr_overrides'
    ],
    redirectUri: 'https://stg-2020source.eyefinity.com'
  },
  showPageSummary: false,
  sessionConfig: {
    idle: 1800,
    timeout: 30
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
