export class Alert {
  constructor(
    public message: string,
    public type: AlertType,
    public alertLink?: string,
    public code?: string,
    public timeout?: number) {
  }
}

export enum AlertType {
  Danger = 'danger',
  Success = 'success',
  Warning = 'warning',
}
