<div class="container-white-full-column welcome">
  <div class="row">
    <div class="col-12">
      <app-spinner message='Loading your content...' *ngIf="!userMessages"></app-spinner>

      <div class="welcome-container" *ngIf="userMessages">
        <div class="row">
          <div class="col-md-12">
            <h1>Welcome</h1>
            <div class="welcome-messages" *ngIf="userMessages">
              <ul>
                <li *ngFor="let userMessage of userMessages.retailMessages">
                  {{userMessage.message}}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7">

            <p class="welcome-message">2020source is a secure website you can use to search for patient eligibility and
              coverage, manage your claims
              from any computer, and can accommodate any number of users you designate to your account.</p>
          </div>
          <div class="col-md-4 offset-md-1">
            <img src="assets/images/retail/home_woman.jpg" alt="" class="welcome-picture">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
