import { IUser } from '@app/core/authentication';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { IUserProfile } from '@app/core/models/user-profile.interface';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public userEmail: string;
  public userAccountNumber: string;
  public hasClaimForm = false;
  private observableSubscriptions: Subscription[] = [];

  private get userProfile() {
    return (this.identity.currentUser || {} as IUser).userProfile || {} as IUserProfile;
  }

  constructor(
    private readonly identity: IdentityProvider,
    private authenticationService: AuthenticationService,
    private readonly router: Router
    ) {
  }


  ngOnInit() {
    this.observableSubscriptions.push(this.identity.isCurrentUserSet.subscribe((isCurrentUserSet: boolean) => {
      if (isCurrentUserSet) {
        this.userEmail = this.userProfile.userEmailAddress;
        this.userAccountNumber = this.userProfile.accountNumber;
        this.hasClaimForm = this.identity.hasClaimFormAccess();
      }
    }));
  }

  isLogged() {
    return this.authenticationService.user.isAuthenticated;
  }

  isAccountAndProfileComplete() {
    return true;
  }

  showUserInfo() {
    return true;
  }

  public logout(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.authenticationService.logout();
    this.router.navigate(['sign-in']);
  }

  ngOnDestroy(): void {
    if (this.observableSubscriptions && this.observableSubscriptions.length > 0) {
      this.observableSubscriptions.forEach(observableSubscription => observableSubscription.unsubscribe());
    }
  }

}
