<footer class="container-white-full-column footer noprint">
    <div class="row">
        <div class="col-12">

            <ul class="footer__nav">
                <li class="footer__nav-item">
                    <!-- FAQ -->
                    <a [routerLink]="['faq']" routerLinkActive="active" id="retail-footer-faq">FAQ</a>
                </li>
                <li class="footer__nav-item" *ngIf="isLoggedIn()">
                    <!-- provider manual-->
                    <a [routerLink]="['provider-manuals']" routerLinkActive="active" id="retail-footer-provider-manual">Provider Manual and Self Service Tools</a>
                </li>
                <li class="footer__nav-item">
                    <!-- Terms and Conditions -->
                    <a [routerLink]="['terms-conditions']" routerLinkActive="active" id="retail-footer-termsAndConditions">Terms and Conditions</a>
                </li>
                <li class="footer__nav-item">
                    <!-- Contact Us -->
                    <a [routerLink]="['contact-us']" routerLinkActive="active" id="retail-footer-contactUs">Contact Us</a>
                </li>
            </ul>

            <p class="footer__copyright">
                <!-- Vision Service Plan Text -->
                <span>© Vision Service Plan <span>{{currentYear}}</span>. All rights reserved </span>
            </p>

          <!-- Build Numbers - Show/Hide per environment config -->
          <div *ngIf="showBuildNumbers" class="float-right" id="footer-buildId">
            <span>Build #: {{buildNumber}} </span>
          </div>

        </div>
    </div>
</footer>
