import { Observable } from 'rxjs';
import { ApiService } from '../../../../core/services/http/api.service';
import { Injectable } from '@angular/core';
import { ContactUsConstants } from '@app/constants';
import { IContactUsList } from '../models/contact-us.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  constructor(private readonly apiService: ApiService) { }


  public getContactUsList(): Observable<IContactUsList> {
    return this.apiService.get(ContactUsConstants.getContactUsUrl);
  }
}
