<div class="container-white-full-column contactUs">
  <div class="row">
    <div class="col-12 mb-4">
      <h1>Contact Us</h1>
    </div>
  </div>

  <!-- Logged In -->
  <div class="row" *ngIf="isLoggedIn()">
    <div class="col-md-12" *ngIf="loadingContactLists">
        <app-spinner message='Loading contact numbers…'></app-spinner>
    </div>
    <ng-template [ngIf]="!loadingContactLists">
      <div class="col-md-7">
        <h4 id="contactUs-content" class="text-center contactUs-email">Email us at: <a appMailTo></a></h4>
      </div>
      <div class="col-md-6">
        <ng-template [ngIf]="contactUsLists">
          <div class="contactUs-phoneList" *ngFor="let list of contactUsLists.retailContactUsList">
            <h2 class="contactUs-phoneList__title">
              {{list.phoneLine}}
            </h2>
            <p class="contactUs-phoneList__number" *ngIf="list.phoneAlias">{{list.phoneAlias}} ({{list.phoneNumber}})
            </p>
            <p class="contactUs-phoneList__number" *ngIf="!list.phoneAlias">{{list.phoneNumber}}</p>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>


  <!-- not Logged In -->
  <div class="row" *ngIf="!isLoggedIn()">
    <div class="col-12">
      <h4 class="text-center contactUs-email">Email us at: <a appMailTo></a></h4>
    </div>
  </div>
</div>
