import { Observable } from 'rxjs';
import { Alert } from '@app/shared/components/alert/alert.model';

export abstract class IAlertService {
  public abstract getAlerts(): Observable<Alert[]>;

  public abstract addDanger(message: string, timeout?: number): Alert;

  public abstract addAlertDanger(message: string, alertLink?: string, code?: string, timeout?: number): Alert;

  public abstract addWarning(message: string, timeout?: number): Alert;

  public abstract addSuccess(message: string, timeout?: number): Alert;

  public abstract removeAlert(alert: Alert): void;

  public abstract clearAlerts(): void;
}
