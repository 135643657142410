import { FormGroup, FormControl, FormArray, AbstractControlOptions, AbstractControl } from '@angular/forms';

export abstract class IFormMapper {
    public abstract appendToGroup<TItem>(form: FormGroup, item: TItem): void;

    public abstract createGroup<TItem>(item: TItem, options?: AbstractControlOptions): FormGroup;

    public abstract createArray<TItem>(items: TItem[]): FormArray;

    public abstract createControl<TItem>(item: TItem): FormControl;

    public abstract setFormControlFromModel(form: AbstractControl, obj: any, parentKey?: string): void;
}
