import { StickyButtonComponent } from './components/sticky-button/sticky-button.component';
import { ReportFooterComponent } from './components/report/report-footer/report-footer.component';
import { ErrorDisplayDirective } from './directives/error-display/error-display.directive';
import { FileLinkDirective } from './directives/file-link/file-link.directive';
import { LoaderInlineComponent } from './loader-inline/loader-inline.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonloaderDirective } from './directives/buttonloader/buttonloader.directive';
import { NgbDatepickerPopupComponent } from './components/datepicker-popup/datepicker-popup.component';
import { NgbdDropdownComponent } from './components/dropdown/dropdown.component';
import { AlertComponent } from './components/alert/alert.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { MailToDirective } from './directives/mail-to/mail-to.directive';
import { SessionTimeoutComponent } from '@app/shared/components/session-timeout/session-timeout.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PhoneNumberPipe } from '@app/shared/pipes/phone-number.pipe';
import { NgbdSortableHeaderDirective } from './directives/table-sortable/table-sortable.directive';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { PasswordRequirementsComponent } from './components/password/password-requirements/password-requirements.component';
import { PasswordEditFormComponent } from './components/password/password-edit-form/password-edit-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordStrengthComponent } from './components/password/password-strength/password-strength.component';
import { RouteTransformerDirective } from '@app/shared/directives/route-transformer/route-transformer.directive';
import { ReportComponent } from './components/report/report.component';
import { ReportHeaderComponent } from './components/report/report-header/report-header.component';
import { ReportGroupComponent } from './components/report/report-group/report-group.component';
import { ReportPatientInfoComponent } from './components/report/report-patient-info/report-patient-info.component';
import { PhoneWithPrefixComponent } from './components/phone-with-prefix/phone-with-prefix.component';
import { DateInFormatDirective } from './directives/date-in-format/date-in-format.directive';
import { CurrencyDirective } from './directives/currency/currency';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    ButtonloaderDirective,
    NgbDatepickerPopupComponent,
    NgbdDropdownComponent,
    AlertComponent,
    MailToDirective,
    SessionTimeoutComponent,
    SpinnerComponent,
    PhoneNumberPipe,
    NgbdSortableHeaderDirective,
    FaqComponent,
    ContactUsComponent,
    LoaderInlineComponent,
    FileLinkDirective,
    ErrorMessageComponent,
    ErrorDisplayDirective,
    PasswordRequirementsComponent,
    PasswordEditFormComponent,
    PasswordStrengthComponent,
    RouteTransformerDirective,
    ReportComponent,
    ReportHeaderComponent,
    ReportGroupComponent,
    ReportPatientInfoComponent,
    ReportFooterComponent,
    StickyButtonComponent,
    PhoneWithPrefixComponent,
    DateInFormatDirective,
    CurrencyDirective,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgbAccordionModule,
    NgbAlertModule,
    ReactiveFormsModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    ButtonloaderDirective,
    NgbDatepickerPopupComponent,
    NgbdDropdownComponent,
    AlertComponent,
    MailToDirective,
    SessionTimeoutComponent,
    SpinnerComponent,
    PhoneNumberPipe,
    NgbdSortableHeaderDirective,
    FaqComponent,
    ContactUsComponent,
    LoaderInlineComponent,
    FileLinkDirective,
    ErrorMessageComponent,
    ErrorDisplayDirective,
    PasswordRequirementsComponent,
    PasswordEditFormComponent,
    RouteTransformerDirective,
    ReportComponent,
    ReportHeaderComponent,
    ReportGroupComponent,
    ReportPatientInfoComponent,
    ReportFooterComponent,
    StickyButtonComponent,
    PhoneWithPrefixComponent,
    DateInFormatDirective,
    CurrencyDirective,
    ConfirmationModalComponent
  ]
})
export class SharedModule { }
