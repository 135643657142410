import { Injectable } from '@angular/core';
import { IdentityConstants } from '@app/constants/identity.constants';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/services/http/api.service';
import { IUserProfile } from '@app/core/models/user-profile.interface';
import { IIdentityService } from './identity-service.interface';

@Injectable({
  providedIn: 'root'
})
export class IdentityService implements IIdentityService {

  constructor(private readonly apiService: ApiService) { }

  public getUserProfile(): Observable<IUserProfile> {
    return this.apiService.get(IdentityConstants.getUserProfileUrl);
  }
}
