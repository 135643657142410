export class CostcoClaimSummaryConstants {
  public static serviceType = {
    V2020: 'Frames',
    V2100: 'Single',
    V2200: 'Bifocal',
    V2300: 'Trifocal',
    V2599: 'Contacts',
    V2744: 'Photochromic',
    V2750: 'AR Treatment',
    V2755: 'UVProtection',
    V2760: 'ScratchResistant',
    V2761: 'Flash Mirror',
    V2762: 'Polarized',
    V2781: 'Progressive',
    V2782: 'Aspheric',
    V2783: 'High-Index',
    V2784: 'Polycarbonate',
    V2799: 'Polish',
  };

  public static serviceCategory = {
    V2020: 'FRAME',
    V2100: 'LENS TYPE',
    V2200: 'LENS TYPE',
    V2300: 'LENS TYPE',
    V2781: 'LENS TYPE',
    V2599: 'CONTACTS',
    V2744: 'LENS ENHANCEMENTS',
    V2755: 'LENS ENHANCEMENTS',
    V2762: 'LENS ENHANCEMENTS',
    V2782: 'LENS ENHANCEMENTS',
    V2783: 'LENS ENHANCEMENTS',
    V2784: 'LENS ENHANCEMENTS',
    V2750: 'ADD-ONS',
    V2760: 'ADD-ONS',
    V2761: 'ADD-ONS',
    V2799: 'ADD-ONS',
  };
}
