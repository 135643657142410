import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {

  @Input()
  public countdown: number;
  @Output()
  public logout: EventEmitter<boolean> = new EventEmitter();

  public secondMapping: {[k: string]: string} = {'=1': 'Second', other: 'Seconds'};

  constructor(private activeModal: NgbActiveModal) { }

  public clickSignOut(): void {
    this.logout.emit(true);
  }

  public closeModal() {
    this.activeModal.dismiss();
  }

}
