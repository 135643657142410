import { ReportMapper } from './../mappers/report.mapper';
import { PatientSearchConstants } from '@app/constants';
import { ApiService } from '@app/core/services/http/api.service';
import { Injectable } from '@angular/core';
import { PatientSearchModel } from '../models/patient-search';
import { IRetailPatientEligibilityResponse, IVerbalizationLinkPayload } from '../models/eligibility-payload.interface';
import { IAlertService } from '@app/core';
import {BehaviorSubject, EMPTY, forkJoin, Observable, Subject} from 'rxjs';
import {pluck, map, switchMap, mergeMap} from 'rxjs/operators';
import { environment } from '../../../../environments';
import { hyphenatedYYYYMMDDToApiDate, isStringNullUndefinedOrEmpty } from '@app/shared/helpers/utility-functions';
import { MemberPolicy, Membership, RetailPatientEligibilityRequest } from '@app/private/patient/models/retail-patient-eligibility-request';
import { SecureConsumerSearchRequest } from '@app/private/patient/models/secure-consumer-search-request';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private patientSearchInputData: PatientSearchModel;
  private isAuthOverride: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private rpeResponse: Subject<IRetailPatientEligibilityResponse>;
  public updatedAuthOverride: Observable<boolean> = this.isAuthOverride.asObservable();


  constructor(
    private alertService: IAlertService,
    private apiService: ApiService,
    private identity: IdentityProvider,
    private reportMapper: ReportMapper
  ) {
    this.isAuthOverride = new BehaviorSubject<boolean>(false);
    this.rpeResponse = new Subject<IRetailPatientEligibilityResponse>();
    this.updatedAuthOverride = this.isAuthOverride.asObservable();
  }

  public search(patientSearchInput: PatientSearchModel, name) {
    patientSearchInput.dateOfBirth =  this.dateConversion(patientSearchInput.dateOfBirth);
    patientSearchInput.dependentDateOfBirth =  this.dateConversion(patientSearchInput.dependentDateOfBirth);
    this.patientSearchInputData = patientSearchInput;

    let retailPatientEligibilityUrl = PatientSearchConstants.retailPatentEligibilityUrl;
    const domainNameForRetailPatientEligibility = environment.apiPathForRetailPatientEligibility;
    if (!isStringNullUndefinedOrEmpty(domainNameForRetailPatientEligibility)) {
      retailPatientEligibilityUrl = `${domainNameForRetailPatientEligibility}/${retailPatientEligibilityUrl}`;
    }
    return this.apiService.post(
      retailPatientEligibilityUrl,
      this.buildRetailPatientEligibilityRequest(patientSearchInput, this.identity, name)).pipe(
      map((retailsResponse: IRetailPatientEligibilityResponse) => {
        if (retailsResponse !== null && name === 'submit') {
          const rpeResponse = retailsResponse.reservations.filter(res => res.authOverrideable);
          if (rpeResponse.length !== 0) {
            const isAuthOverrideable = rpeResponse.pop().authOverrideable;
            if (isAuthOverrideable) {
              this.alertService.addDanger(PatientSearchConstants.additionalAuth);
              this.isAuthOverride.next(true);
              return EMPTY;
            }
          }
        }
        return retailsResponse;
      })
    );
  }
  public getPatientSearchInputData() {
    return this.patientSearchInputData;
  }
  public dateConversion(value: any): string {
    if (value && !value.includes('-')) {
      const dateSplit = value.split('/');
      value = ([dateSplit[2], dateSplit[0], dateSplit[1]].join('-'));
    }
    return value;
  }

  public getReportData(responseFromRPE: IRetailPatientEligibilityResponse) {
    const domainNameForEligibility = environment.apiPathForEligibility;
    let eligibilityUrls = responseFromRPE.reservations.filter(res => res.verbalizationLink && res.verbalizationLink.href).map(
      res => this.buildJsonParams(res.verbalizationLink.href, res.vsrnumber));

    if (eligibilityUrls.length === 0) {
      eligibilityUrls.push(this.buildJsonParams(responseFromRPE.verbalizationLink.href, null));
    }

    if (!isStringNullUndefinedOrEmpty(domainNameForEligibility)) {
      eligibilityUrls = eligibilityUrls.map(elem =>
        this.buildJsonParams(elem.href.replace(PatientSearchConstants.domainOfUrlRegex, domainNameForEligibility), elem.vsrNumber));
    }

    const observables = eligibilityUrls.map(elem => this.getDataFromVerbalization(responseFromRPE, elem.href, elem.vsrNumber));
    return forkJoin(observables);
  }

  private buildJsonParams(hrefUrl: string, vsrNum: string): any {
    return {
      href: hrefUrl,
      vsrNumber: vsrNum
    };
  }
  private getDataFromVerbalization(responseFromRPE: IRetailPatientEligibilityResponse,
                                   hrefUrl: string, vsrNumber: string): Observable<any> {
    return this.apiService.get(hrefUrl).pipe(
      pluck('networks'),
      map((v: IVerbalizationLinkPayload[]) => this.reportMapper.mapReports(responseFromRPE, v[0].productPackages, vsrNumber))
    );
  }

  private buildRetailPatientEligibilityRequest(patientSearchInput: PatientSearchModel,
                                               identity: IdentityProvider, name): RetailPatientEligibilityRequest {
    try {
      const retailPatientEligibilityRequest: RetailPatientEligibilityRequest = {
        criteria: {
          insuredFirstName: patientSearchInput.firstName,
          insuredLastName: patientSearchInput.lastName,
          insuredDOB: hyphenatedYYYYMMDDToApiDate(patientSearchInput.dateOfBirth),
          dateOfService: hyphenatedYYYYMMDDToApiDate(patientSearchInput.asofdate),
          insuredId: patientSearchInput.policyId ? patientSearchInput.policyId : '',
          insuredLast4: patientSearchInput.lastFourSSN ? patientSearchInput.lastFourSSN : ''
        },
        providerNetworks: identity.currentUser.userProfile.networkIds,
        serviceType: identity.currentUser.userProfile.serviceType,
        memberPolicies: null,
        dependentNotFound: false,
        overrideVisionServiceRequest: name === 'continue' ? true : false
      };
      if (patientSearchInput.isDependent) {
        retailPatientEligibilityRequest.criteria.dependentFirstName = patientSearchInput.dependentFirstName;
        retailPatientEligibilityRequest.criteria.dependentLastName = patientSearchInput.dependentLastName;
        retailPatientEligibilityRequest.criteria.dependentDOB = hyphenatedYYYYMMDDToApiDate(patientSearchInput.dependentDateOfBirth);
      }
      // tslint:disable-next-line:max-line-length

      return retailPatientEligibilityRequest;
    } catch (exception) {
      console.log(exception);
      return null;
    }
  }

  private buildRetailPatientFailureEligibilityRequest(memberPoliciesResponse: MemberPolicy[], patientSearchInput: PatientSearchModel,
                                                      identity: IdentityProvider): RetailPatientEligibilityRequest {
    try {
      const retailPatientEligibilityRequest: RetailPatientEligibilityRequest = {
        criteria: {
          insuredFirstName: patientSearchInput.firstName,
          insuredLastName: patientSearchInput.lastName,
          insuredDOB: hyphenatedYYYYMMDDToApiDate(patientSearchInput.dateOfBirth),
          dateOfService: hyphenatedYYYYMMDDToApiDate(patientSearchInput.asofdate),
          insuredId: patientSearchInput.policyId ? patientSearchInput.policyId : '',
          insuredLast4: patientSearchInput.lastFourSSN ? patientSearchInput.lastFourSSN : ''
        },
        providerNetworks: identity.currentUser.userProfile.networkIds,
        serviceType: identity.currentUser.userProfile.serviceType,
        memberPolicies: this.getMemberPoliciesData(memberPoliciesResponse),
        dependentNotFound: true,
        overrideVisionServiceRequest: false
      };
      if (retailPatientEligibilityRequest.memberPolicies.length === 0)  {
        delete retailPatientEligibilityRequest.memberPolicies;
        delete retailPatientEligibilityRequest.serviceType;

      }
      return retailPatientEligibilityRequest;
    } catch (exception) {
      console.log(exception);
      return null;
    }
  }
  private getMemberPoliciesData(memberPoliciesResponse: MemberPolicy[]) {
    if (memberPoliciesResponse.length !== 0) {
      return memberPoliciesResponse.map((memberPolicy: MemberPolicy) => ({
        policyKey: memberPolicy.policyKey,
        policyEffectiveDate: memberPolicy.policyEffectiveDate,
        classId: memberPolicy.classId,
        memberships: memberPolicy.memberships.map((membership: Membership) => ({
          consumerId: membership.consumerId,
          status: membership.status
        }))
      }));
    } else {
      return [];
    }
  }

  private buildSecureConsumerSearchRequest(patientSearchInput: PatientSearchModel): SecureConsumerSearchRequest {
    return {
      firstName: patientSearchInput.firstName,
      lastName: patientSearchInput.lastName,
      dateOfBirth: patientSearchInput.dateOfBirth,
      asofdate: patientSearchInput.asofdate,
      policyId: patientSearchInput.policyId ? patientSearchInput.policyId.toUpperCase() : patientSearchInput.lastFourSSN,
      dependentFirstName: patientSearchInput.dependentFirstName,
      dependentLastName: patientSearchInput.dependentLastName,
      dependentDateOfBirth: patientSearchInput.dependentDateOfBirth
    } as SecureConsumerSearchRequest;
  }

  getRetailEligibilityAPIError(): Observable<any> {
    return this.apiService.post(
      PatientSearchConstants.retailPatentEligibilityUrl,
      this.buildRetailPatientFailureEligibilityRequest([], this.getPatientSearchInputData(), this.identity)).pipe(
      mergeMap((retailsResponse: any[]) => {
        if (!retailsResponse || retailsResponse.length === 0) {
          return EMPTY;
        }
      })
    );
  }

  public continue(patientSearchInput: PatientSearchModel, name) {
    patientSearchInput.dateOfBirth =  this.dateConversion(patientSearchInput.dateOfBirth);
    patientSearchInput.dependentDateOfBirth =  this.dateConversion(patientSearchInput.dependentDateOfBirth);
    this.patientSearchInputData = patientSearchInput;
    return this.apiService.post(
      PatientSearchConstants.retailPatentEligibilityUrl,
      this.buildRetailPatientEligibilityRequest(patientSearchInput, this.identity, name)
    );
  }

}
