import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  public goToSignIn() {
    this.authenticationService.login();
  }

}
