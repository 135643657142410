<div class="vsp-container session-timeout">
  <div class="modal-close-button" (click)="closeModal()">
    <i class="vspicon-vsp_close"></i>
  </div>

  <div class="modal-icon-container">
    <i class="vspicon-vsp_error_circle c-orange"></i>
  </div>

  <h2>We haven't heard from you in a while.</h2>
  <p class="session-timeout__reason">For security reasons your session will expire in</p>
  <p class="session-timeout__countdown">{{countdown}}</p>
  <p class="session-timeout__countdown-label">{{countdown | i18nPlural: secondMapping}}</p>

  <button type="button" class="btn btn-primary session-timeout__keep-signed-in" (click)="closeModal()">Keep Me Signed In</button>
  <br/>
  <p class="session-timeout__sign-out" (click)="clickSignOut()">Sign Out</p>
</div>
