<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-6">
        <strong class="strong"> Patient Identification</strong>
      </div>
      <div class="col-6 row justify-content-end">
        <strong class="strong">Authorization#&nbsp;</strong>
        <strong class="strong">{{claim.trackingNumber}}</strong>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-7 offset-1" id="patientName">
        <label id="labelPatientName">Patient Name:&nbsp;</label>
        <span id="dataPatientName">{{claim.patient.name.firstName}} {{claim.patient.name.lastName}}</span>
      </div>

      <div class="col-3 row justify-content-end align-items-baseline" id="patientDateOfBitrh">
        <label id="labelPatientDateOfBitrh">Birth Date:&nbsp;</label>
        <span id="dataPatientDateOfBitrh">{{claim.patient.dateOfBirth}}</span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div id="relationship">
        <label id="labelRelationship">Relationship:&nbsp;</label>
        <span id="dataRelationship">{{relationship}}</span>
      </div>
    </div>

    <div class="row" id="memberName">
      <div class="col-7 offset-1">
        <label id="labelMemberName">Member Name:&nbsp;</label>
        <span id="dataMemberName">{{claim.member.name.firstName}} {{claim.member.name.lastName}}</span>
      </div>

      <div class="col-3 row justify-content-end align-items-baseline" id="memberDateOfBirth">
        <label id="labelMemberDateOfBirth">Birth Date:&nbsp;</label>
        <span id="dataMemberDateOfBirth">{{claim.member.dateOfBirth}}</span>
      </div>
    </div>

  </div>
</div>

<br>

<div class="card">
  <div class="card-header">
    <strong class="strong">Summary</strong>
  </div>
  <div class="card-body">

    <div align="center" id="barcode">
      <ngx-barcode6 [bc-value]="sku" [bc-display-value]="true" [bc-format]="'ITF'"
                   [bc-element-type]="'img'" [bc-font-size]="24"></ngx-barcode6>
      <br>
      <strong style="font-size:15px">VSP Eligibility ID</strong>

    </div>
    <br> <br>
    <div class="row">
      <div class="col-1">
      </div>

      <div class="col-3">
        <label>Lens Item#</label>
        <span>&nbsp;{{claim.itemNumber}}</span>
      </div>
      <div class="col-2">
        <label>Cost</label>
        <span>&nbsp;{{claim.itemCostAmount}}</span>
      </div>
      <div class="col-6">
        <label>Material:</label>
        <span>&nbsp;{{claim.lensDescription}}</span>
      </div>

    </div>
    <br>

    <div class="container-fluid">
      <div class="row">
        <div class="col-5"></div>
        <div class="col-2">
          <label>Service</label>
        </div>
        <div class="col-1">
          <label>CPT/HCPC</label>
        </div>
        <div class="col-2">
          <!--These 3 labels are a hack to get the Print Preview to line up-->
          <label> </label>
          <label> </label>
          <label> </label>
          <label>Charges</label>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="row" *ngFor="let data of claim.serviceLines; index as i">
        <div class="col-1"></div>
        <div class="col-4 strong">
          <label id="{{'labelSvcCategory' + i}}">{{
            getServiceCategory(data.cptHcpcsCode)}} </label>
        </div>
        <div class="col-2">
          <span id="{{'spanSvcTyp' + i}}">{{ getServiceType(data.cptHcpcsCode)}} </span>
        </div>
        <div class="col-1 service-info">
          <span id="{{'spanHCPC' + i}}">{{data.cptHcpcsCode}}</span>
        </div>
        <div class="col-1 service-info">
          <span id="{{'spanCharges' + i}}">{{data.billedAmount | currency}}</span>
        </div>
        <div class="col-3"></div>
      </div>

      <br><br>
      <div class="row justify-content-center align-items-baseline">
        <label>Costco Charges</label>
        <span>&nbsp;{{totalCharges | currency}}</span>
      </div>
      <div class="row justify-content-center align-items-baseline">
        <label>Patient Pays</label>
        <span>&nbsp;{{claim.patientPaymentAmount | currency}}</span>
      </div>
      <div class="row justify-content-center align-items-baseline">
        <strong style="font-size:20px">VSP Pays {{vspPays | currency}}</strong>
      </div>
    </div>

  </div>
</div>
