import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '@app/core';
import { ProviderManualsComponent } from './provider-manuals/provider-manuals.component';
import { CostcoClaimSummaryComponent } from '@app/private/costco-claim-summary/costco-claim-summary.component';
import { PdfViewerComponent } from '@app/private/pdf-viewer/pdf-viewer.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  { path: 'oauth_redirect.html',
    canLoad: [AuthGuard],
    pathMatch: 'full',
    redirectTo: '' },
  {
    path: 'materials-pagesummary',
    component: CostcoClaimSummaryComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pdf-viewer',
    component: PdfViewerComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'account-profile',
    canLoad: [AuthGuard],
    loadChildren: () => import('./account/account.module')
      .then(m => m.AccountModule)
  },
  {
    path: 'claims',
    canLoad: [AuthGuard],
    loadChildren: () => import('./claims/claims.module')
      .then(m => m.ClaimsModule)
  },
  {
    path: 'patient',
    canLoad: [AuthGuard],
    loadChildren: () => import('./patient/patient.module')
      .then(m => m.PatientModule)
  },
  {
    path: 'provider-manuals',
    canLoad: [AuthGuard],
    loadChildren: () => import('./provider-manuals/provider-manuals.module')
      .then(m => m.ProviderManualsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
