<div class="container sign-in">
    <div class="sign-in__wrapper">

        <!-- Glasses Image -->
        <img src="assets/images/retail/glasses.jpg" class="sign-in__wrapper__image" />

        <br>

        <!-- Sign In Button -->
        <button type="button" class="btn btn-primary sign-in__wrapper__button" (click)="goToSignIn()" id="signIn-signInButton">
            <span>Sign In</span>
        </button>
    </div>
</div>