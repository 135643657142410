<header class="container-white-full-column header noprint">
  <div class="row">
    <div class="col-12">
      <div class="header__wrapper">
        <!-- Title Logo -->
        <a [routerLink]="['']" class="inline-block" id="header-title">
          <img src="assets/images/retail/2020source.png" class="inline-block" />
        </a>

        <ng-container *ngIf="isLogged()">
        <div class="header__retail">
          <nav class="header__retail-nav" *ngIf="isAccountAndProfileComplete()">
            <a [routerLink]="['patient']" class="header__retail-nav-link"
              id="header-patientsLink" routerLinkActive="active">Patient Search</a>
            <a *ngIf="hasClaimForm" [routerLink]="['claims']" class="header__retail-nav-link"
              id="header-claimsLink" routerLinkActive="active">Claims</a>
          </nav>

          <ul class="header__retail-account-section">
            <!-- ngIf: showUserInfo() -->
            <ng-container *ngIf="showUserInfo()">
              <li class='header__retail-account-section-item' id="header-emailAddress">
                <i class="vspicon-vsp_people_policyholder"></i>
                {{userEmail}}
              </li>

              <li class='header__retail-account-section-item' id="header-accountNumber">
                #{{userAccountNumber}}
              </li>
            </ng-container><!-- end ngIf: showUserInfo() -->

            <li class='header__retail-account-section-item'>
                <a [routerLink]="['account-profile']" id="header-accountProfile" routerLinkActive="active">Your Account</a>
            </li>

            <li class='header__retail-account-section-item'>
                <a href="" (click)="logout($event)" id="oauth-logout">Sign Out</a>
            </li>

        </ul>
        </div>
        </ng-container>

      </div>

    </div>
  </div>

</header>
