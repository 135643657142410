import { Component } from '@angular/core';
import { IClaim } from '@app/private/claims/models/claim.interface';

@Component({
  selector: 'app-costco-claim-summary',
  templateUrl: './costco-claim-summary.component.html',
  styleUrls: ['./costco-claim-summary.component.scss']
})
export class CostcoClaimSummaryComponent {

  public get claim() {
    return window.sessionStorage.getItem('pageSummaryData') ?
      JSON.parse(window.sessionStorage.getItem('pageSummaryData')) as IClaim :
      undefined;
  }

  constructor() { }

}
