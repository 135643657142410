import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from 'src/environments';
import { Observable } from 'rxjs';
import { IHttpClient } from './http-client.interface';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseHttpService {
  protected get serviceUrl() {
    return environment.apiPath;
  }

  constructor(
    httpClient: IHttpClient,
    private authenticationService: AuthenticationService
    ) {
    super(httpClient);
  }

  public post<TResult>(relativeUrl: string, params?: any, options?: any): Observable<TResult> {
    return super.post<TResult>(
      relativeUrl,
      { ...(params || {}) },
      { headers: { Authorization: this.authenticationService.user.token }, ...options });
  }

  public get<TResult>(relativeUrl: string, params?: any, options?: any): Observable<TResult> {
    const secureApiParams = { headers: { Authorization: this.authenticationService.user.token }, ...options, ...params };
    return super.get<TResult>(
      relativeUrl,
      { ...(secureApiParams || {}), t: new Date().getTime() });
  }

  public put<TResult>(relativeUrl: string, params?: any, options?: any): Observable<TResult> {
    return super.put<TResult>(
      relativeUrl,
      { ...(params || {}) },
      { headers: { Authorization: this.authenticationService.user.token }, ...options });
  }

  public delete<TResult>(relativeUrl: string, options?: any): Observable<TResult> {
    return super.delete<TResult>(
      relativeUrl,
      { headers: { Authorization: this.authenticationService.user.token }, ...options });
  }
}
