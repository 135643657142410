import { Component, Input, OnInit } from '@angular/core';
import { IClaim, IServiceLine } from '@app/private/claims/models/claim.interface';
import { CostcoClaimSummaryConstants } from '@app/constants/costco-claim-summary.constants';
import { luhnCalculate, luhnValidate } from '@app/shared/helpers/luhn-algorithm';

@Component({
  selector: 'app-claim-summary-body',
  templateUrl: './claim-summary-body.component.html',
  styleUrls: ['./claim-summary-body.component.scss', '../costco-claim-summary.component.scss']
})
export class ClaimSummaryBodyComponent implements OnInit {
  @Input() claim: IClaim;

  constructor() { }

  ngOnInit() {
  }

  public get relationship(): string {
    const relationToSubscriber = this.claim.patient.relationToSubscriber;
    return relationToSubscriber && relationToSubscriber.toUpperCase() === 'MEMBER' ? 'Self' : 'Dependent';
  }

  public get totalCharges(): number {
    return (this.claim.serviceLines as []).reduce((previous, current: IServiceLine) => {
      return previous + current.billedAmount;
    }, 0);
  }

  public get vspPays(): number {
    return this.totalCharges - Number(this.claim.patientPaymentAmount || 0);
  }

  public get sku(): string {
    const skuId = '710';
    const skuMiddle = this.createSKUMiddle(this.claim.trackingNumber);
    const skuCheckDigit = luhnCalculate(skuId + skuMiddle);
    const fullSku = skuId + skuMiddle + skuCheckDigit;

    return luhnValidate(fullSku) ? fullSku : null;
  }

  public getServiceCategory(code: string): string {
    return CostcoClaimSummaryConstants.serviceCategory[code];
  }

  public getServiceType(code: string): string {
    return CostcoClaimSummaryConstants.serviceType[code];
  }

  private createSKUMiddle(trackingNumber): string {
    const YY = new Date().getFullYear().toString().substr(-2);
    const MM = ('0' + (new Date().getMonth() + 1)).slice(-2);
    return YY + MM + trackingNumber;
  }
}
