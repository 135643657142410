import {
  Component, OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { ClaimsHistoryService } from '@app/private/claims/claims-history/services/claims-history.service';
import { ActivatedRoute } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, OnDestroy {

  pdfUrl: SafeResourceUrl;
  loading: boolean;
  subscription: Subscription;

  constructor(private sanitizer: DomSanitizer,
              private readonly claimsHistoryService: ClaimsHistoryService,
              private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.loading = true;
    const httpResult$ = this.route.queryParams.pipe(
      // rxjs operator skip empty object
      filter(params => !!Object.keys(params).length),
      // rxjs operator use only first event
      first(),
      // rxjs operator switch to another observable
      switchMap(params => {
        return this.claimsHistoryService.getClaimSummaryFile(params.accountNumber, params.trackingNumber, params.dateOfService);
      }),
    );

    this.subscription = httpResult$.subscribe(
      (response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const fileUrl = window.URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
      },
      console.error,
      () => {
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
