import { filter } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IAlertService } from '@app/core/services/alert/alert.service.interface';
import { Alert, AlertType } from '@app/shared/components/alert/alert.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() public service: IAlertService;
  @Input() public sticky = false;
  constructor(private readonly alertService: IAlertService, private readonly router: Router) { }

  public alerts: Observable<Alert[]>;
  private routerSubscription: Subscription;

  public ngOnInit(): void {
    this.service = this.service || this.alertService;
    this.alerts = this.service.getAlerts();
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.service.clearAlerts());
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public isSuccess(alert: Alert): boolean {
    return alert.type === AlertType.Success;
  }

  public removeAlert(alert: Alert) {
    this.service.removeAlert(alert);
  }
}
