import { Component, OnInit } from '@angular/core';
import { ContactUsService } from './services/contact-us.service';
import { IContactUsList } from './models/contact-us.interface';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(public contactUsService: ContactUsService, private readonly identity: IdentityProvider) { }

  contactUsLists: IContactUsList;
  loadingContactLists = false;

  ngOnInit() {
    if (this.isLoggedIn()) {
      this.getContactUs();
    }
  }


  isLoggedIn(): boolean {
    return this.identity.currentUser.isAuthenticated;
  }

  getContactUs(): void {
    this.loadingContactLists = true;
    this.contactUsService.getContactUsList()
      .subscribe(data => {
        if (data.retailContactUsList || data.retailContactUsList === undefined) {
          this.contactUsLists = data;
          this.loadingContactLists = false;
        }
      });
  }


}
