<div class="container-white-full-column saml-section">

  <div class="row">

    <div class="col-12">
      <p class="saml-section-header">We're Sorry.</p>
    </div>

    <div *ngIf="isDataError" class="col-12">
      <p id="dataError">An error was encountered.  Please email <a appMailTo></a> for further assistance.</p>
    </div>

    <div *ngIf="!isDataError" class="col-12">
      <p id="nonDataError">You are not authorized to access this VSP portal.  If you feel this is incorrect, please work with your direct supervisor to ensure your job coded correctly for Vision Center.</p>
    </div>

  </div>

</div>
