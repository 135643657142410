import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-saml-error',
  templateUrl: './saml-error.component.html',
  styleUrls: ['./saml-error.component.scss']
})
export class SamlErrorComponent implements OnInit {

  isDataError: boolean;

  constructor(private readonly router: Router) { }

  ngOnInit() {
    this.isDataError = this.router.url && this.router.url.trim().toLowerCase() === '/saml-data-error';
  }

}
