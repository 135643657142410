<div class="row">
  <div class="col-12">

    <div class="row">

      <div class="col-6 ">
        <div class="row">
          <label id="labelPatClmSum">Patient Claim Summary</label>
        </div>
        <div class="row">
          <label id="labelPHI">Confidential - includes protected health
            information.</label>
        </div>
        <div class="row">
          <label class="strong" id="labelDOS">Patient Date of Service </label> &nbsp;
          <span class="small"
                id="dataPatientDOS">{{claim.dateOfService}}</span>
        </div>
      </div>
      <div class="col-6">
        <div class="row justify-content-end">
          <div class="small">
            <label>Account No:&nbsp;</label>
            <span>{{userProfile.accountNumber}}</span>
          </div>
        </div>
        <div class="row justify-content-end">
          <label class="small">{{userProfile.userFirstName}}
            {{userProfile.userLastName}} </label>
        </div>
        <div class="row justify-content-end">
          <label class="small">{{userProfile.userEmailAddress}}</label>
        </div>
        <div class="row justify-content-end">
          <div class="small">
            <label>Report Prepared:&nbsp;</label>
            <span>{{reportGeneratedDate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
