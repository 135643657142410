import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

import { IHttpClient } from './http-client.interface';

@Injectable()
export abstract class BaseHttpService {
  protected abstract get serviceUrl(): string;

  constructor(
    protected readonly httpClient: IHttpClient) { }

  protected get<TResult>(url: string, params?: any): Observable<TResult> {
    return this.httpClient
      .get<TResult>(this.buildUrl(url), params)
      .pipe(
        retry(1)
      );
  }

  protected post<TResult>(
    url: string,
    params: any,
    options?: { headers: HttpHeaders | { [header: string]: string | string[] } }): Observable<TResult> {
    return this.httpClient
      .post<TResult>(this.buildUrl(url), params, options)
      .pipe(
        retry(1)
      );
  }

  protected put<TResult>(
    relativeUrl: string,
    params: any,
    options?: { headers: HttpHeaders | { [header: string]: string | string[] } }): Observable<TResult> {
    return this.httpClient
      .put<TResult>(this.buildUrl(relativeUrl), params, options)
      .pipe(
        retry(1)
      );
  }

  protected delete<TResult>(
    relativeUrl: string,
    options?: { headers: HttpHeaders | { [header: string]: string | string[] } }): Observable<TResult> {
    return this.httpClient
      .delete<TResult>(this.buildUrl(relativeUrl), options)
      .pipe(
        retry(1)
      );
  }
  private buildUrl(url: string): string {
    return this.isRelativeUrl(url) ? (`${this.serviceUrl}/${(url || '')}`).replace(/[?&]$/, '') : url;
  }

  private isRelativeUrl(url: string) {
    return !url.startsWith('http');
  }

}
