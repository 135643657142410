import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/http/api.service';
import { Injectable } from '@angular/core';
import { SystemMessagesConstants } from '@app/constants/system-messages.constants';
import { ISystemMessage } from '../models/system-message.interface';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private readonly apiService: ApiService) { }


  public getSystemMessages(): Observable<ISystemMessage> {
    return this.apiService.get(SystemMessagesConstants.getSystemMessagesUrl);
  }
}
