import { Component, Input, OnInit } from '@angular/core';
import { IUserProfile } from '@app/core';
import { IClaim } from '@app/private/claims/models/claim.interface';
import { formatPageSummaryGenDate } from '@app/core/services/date-utils.service';
import { IdentityProvider } from '@app/core/providers/identity/identity-provider';

@Component({
  selector: 'app-claim-summary-header',
  templateUrl: './claim-summary-header.component.html',
  styleUrls: ['./claim-summary-header.component.scss', '../costco-claim-summary.component.scss']
})
export class ClaimSummaryHeaderComponent implements OnInit {
  @Input() claim: IClaim;
  public reportGeneratedDate: string;

  constructor(private readonly identity: IdentityProvider) { }

  public get userProfile(): IUserProfile {
    return this.identity.currentUser.userProfile || {} as IUserProfile;
  }

  ngOnInit() {
    this.reportGeneratedDate = formatPageSummaryGenDate();
  }

}
