export function isStringNullUndefinedOrEmpty(value: string): boolean {
  return isNullOrUndefined(value) || value.length === 0;
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function hyphenatedYYYYMMDDToApiDate(date: string): string {
  if (isStringNullUndefinedOrEmpty(date)) {
    return '';
  }
  const dateSplit = date.split('-');
  const month = dateSplit[1].length === 1 ? '0' + dateSplit[1] : dateSplit[1];
  const day = dateSplit[2].length === 1 ? '0' + dateSplit[2] : dateSplit[2];
  const year = dateSplit[0];
  return month + '/' + day + '/' + year;
}
