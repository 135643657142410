import { Component, OnInit } from '@angular/core';
import { MessagesService } from './services/system-messages.service';
import { ISystemMessage } from './models/system-message.interface';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private messageService: MessagesService) { }

  message: any;
  userMessages: ISystemMessage;

  ngOnInit() {
    this.getMessages();
  }

  getMessages(): void {
    this.messageService.getSystemMessages()
      .subscribe((data: any) => {
        this.userMessages = data;
      }, error => {
        this.userMessages = error;
      });
  }
}
