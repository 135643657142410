import {Injectable} from '@angular/core';
import {IAlertService} from './alert.service.interface';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {Alert, AlertType} from '@app/shared/components/alert/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService implements IAlertService {
  private subject: Subject<Alert[]>;
  private alerts: Alert[];

  public constructor() {
    this.subject = new Subject<Alert[]>();
    this.alerts = [];
  }

  public getAlerts(): Observable<Alert[]> {
    return this.subject.asObservable();
  }

  public addDanger(message: string, timeout?: number): Alert {
    const alert = new Alert(message, AlertType.Danger, '', '', timeout);
    this.alert(alert);

    return alert;
  }

  public addAlertDanger(message: string, alertLink?: string, messageCode?: string,  timeout?: number): Alert {
    const alert = new Alert(message, AlertType.Danger, alertLink, messageCode, timeout);
    this.alert(alert);

    return alert;
  }

  public addSuccess(message: string, timeout?: number): Alert {
    const alert = new Alert(message, AlertType.Success, '', '', timeout);
    this.alert(alert);

    return alert;
  }

  public addWarning(message: string, timeout?: number): Alert {
    const alert = new Alert(message, AlertType.Warning, '', '', timeout);
    this.alert(alert);

    return alert;
  }

  private alert(alert: Alert): void {
    this.alerts.push(alert);

    if (alert.timeout) { this.timeoutAlert(alert); }

    this.subject.next(this.alerts);
  }

  private timeoutAlert(alert: Alert): void {
    setTimeout(() => {
      this.removeAlert(alert);
      this.subject.next(this.alerts);
    }, alert.timeout);
  }

  public removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter(a => a !== alert);
    this.subject.next(this.alerts);
  }

  public clearAlerts(): void {
    this.alerts = [];
    this.subject.next(this.alerts);
  }
}
