export class PatientSearchConstants {
  public static retailPatentEligibilityUrl = 'retail-eligibility/retail-eligibilities';
  public static domainOfUrlRegex = new RegExp(/^.*\/\/[^\/]+/);
  public static additionalAuth = 'An outstanding authorization exists.' +
    ' Please confirm with patient if materials were received at another location.' +
    ' If no, click continue to proceed.' +
    ' If yes, patient is not eligible for services and click reset.' +
    ' If the member has questions, please refer them to VSP at 800.877.7195. (Message #65)';
  public static noDependentsFound  = 'No dependents were found. Please have the member contact VSP for further ' +
    'assistance. Message #19';
  public static EMPTY_RELATIONSHIP = 'Please indicate the Relationship to the Insured';
  public static EMPTY_DATE_OF_SERVICE = 'Please supply the Date of Service';
  public static EMPTY_INSURED_FIRST_NAME = 'Please supply the Insured\'s First Name';
  public static INVALID_INSURED_FIRST_NAME = 'An invalid name has been entered. Please supply the Insured\'s First Name';
  public static INVALID_CHARACTER_INSURED_FIRST_NAME = 'An invalid character has been entered. Please supply the Insured\'s First Name';
  public static EMPTY_INSURED_LAST_NAME = 'Please supply the Insured\'s Last Name';
  public static INVALID_INSURED_LAST_NAME = 'An invalid name has been entered. Please supply the Insured\'s Last Name';
  public static INVALID_CHARACTER_INSURED_LAST_NAME = 'An invalid character has been entered. Please supply the Insured\'s Last Name';
  public static EMPTY_INSURED_DATE_OF_BIRTH = 'Please supply the Insured\'s Date of Birth';
  public static EMPTY_DEPENDENT_FIRST_NAME = 'Please supply the Patient\'s First Name';
  public static INVALID_DEPENDENT_FIRST_NAME = 'An invalid name has been entered. Please supply the Patient\'s First Name';
  public static INVALID_CHARACTER_DEPENDENT_FIRST_NAME = 'An invalid character has been entered. Please supply the Patient\'s First Name';
  public static EMPTY_DEPENDENT_LAST_NAME = 'Please supply the Patient\'s Last Name';
  public static INVALID_DEPENDENT_LAST_NAME = 'An invalid name has been entered. Please supply the Patient\'s Last Name';
  public static INVALID_CHARACTER_DEPENDENT_LAST_NAME = 'An invalid character has been entered. Please supply the Patient\'s Last Name';
  public static EMPTY_DEPENDENT_DATE_OF_BIRTH = 'Please supply the Patient\'s Date of Birth';
  public static EMPTY_POLICY_ID = 'Please supply the full Insured ID Number';
  public static INVALID_POLICY_ID = 'An invalid character has been input. Please supply the full Insured ID Number';
  public static EMPTY_LAST_FOUR_SSN = 'Please supply the Last 4 characters of the Insured\'s SSN';
  public static INVALID_LAST_FOUR_SSN_CHARACTER = 'An invalid character has been input. Please supply the Last 4 characters of the Insured\'s SSN';
  public static INVALID_LAST_FOUR_SSN_LENGTH = 'Last 4 of SSN field requires 4 digits';
  public static MAX_DATE_INPUT_LENGTH = 'Date field required 10 digits including /';
  public static MONTH_VALUE_OUT_OF_RANGE = 'Please enter month value between 1 and 12';
  public static DAY_VALUE_OUT_OF_RANGE = 'Please enter day value between 1 and ';
}
