import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sticky-button',
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss']
})
export class StickyButtonComponent implements OnInit {

  @Input() public iconClass: string;
  constructor() { }

  ngOnInit() {
  }

}
