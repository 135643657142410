<div class="alerts" *ngIf="alerts | async as al" [ngClass]="{'sticky': sticky}">
  <ngb-alert id="close-button" (close)="removeAlert(alert)" [type]="alert.type" *ngFor="let alert of al">
    <i *ngIf="isSuccess(alert)" class="vspicon-vsp_check_circle alert-icon"></i>
    <span>
      {{ alert.message}}
    </span>
    <span class = "alert-span-style">
      <a [routerLink]="['contact-us']" routerLinkActive="active" id="retail-alert-contactUs">{{alert.alertLink}}
        </a></span>
    <span>
      {{alert.code}}
    </span>
  </ngb-alert>
</div>
