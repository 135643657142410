import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {isNullOrUndefined, isStringNullUndefinedOrEmpty} from '@app/shared/helpers/utility-functions';
import {environment, IEnvironment} from '../environments';
import {CommonConstants} from '@app/constants/common.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly SCRIPT_TAG = 'script';
  private BLANK = '';
  private iEnvironment: IEnvironment;

  constructor(
    private router: Router) {
    this.iEnvironment = environment;
  }

  public get isSummaryPage(): boolean {
    return this.router.url.includes('materials-pagesummary');
  }

  ngOnInit(): void {
    this.loadDynatraceLibrary();
  }

  /**
   * Note this logic was added for RETAIL-194 to add Dynatrace traceability into the app.
   */
  private loadDynatraceLibrary() {
    if (!isNullOrUndefined(environment)
        && !isStringNullUndefinedOrEmpty(environment.envName)
        && !isStringNullUndefinedOrEmpty(environment.dynatraceUrl)) {
      const lowerCaseEnvironmentName: string = environment.envName.trim().toLowerCase();
      if (lowerCaseEnvironmentName === CommonConstants.STAGING || lowerCaseEnvironmentName === CommonConstants.PRODUCTION) {
        const body = document.body as HTMLDivElement;
        const dynatraceScript = document.createElement(this.SCRIPT_TAG);
        dynatraceScript.innerHTML = this.BLANK;
        dynatraceScript.async = true;
        dynatraceScript.crossOrigin = 'anonymous';
        dynatraceScript.src = environment.dynatraceUrl;
        body.appendChild(dynatraceScript);
      }
    }
  }
}
