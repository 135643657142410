export function luhnChecksum(code) {
  const len = code.length;
  const parity = len % 2;
  let sum = 0;
  for (let i = len - 1; i >= 0; i--) {
    let d = Number(code.charAt(i));
    if (i % 2 === parity) { d *= 2; }
    if (d > 9) { d -= 9; }
    sum += d;
  }
  return sum % 10;
}

export function luhnCalculate(partCode) {
  const checksum = luhnChecksum(partCode + '0');
  return checksum === 0 ? 0 : 10 - checksum;
}

export function luhnValidate(fullCode) {
  return luhnChecksum(fullCode) === 0;
}
