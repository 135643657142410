<div class="container-white-full-column terms-and-conditions">

    <div class="row">
        <div class="col-12">

            <div id="retail-termsAndConditions">

                <h1 class="terms-and-conditions-title">Terms and Conditions</h1>

                <p>2020source TERMS AND CONDITIONS OF USE<br/>
                    for Providers and Opticians</p>


                <p class="terms-and-conditions__section-header">Acceptance of Terms</p>

                <p>Welcome to 2020source. If you access our site and make use of its current or future services, you accept
                    these
                    terms and conditions of use. Please read these terms and conditions carefully. 2020source is permitting you
                    to
                    use the site subject to this agreement, as well as our privacy policy, legal notices and any other written
                    agreement between the parties concerning the site. If you do not agree to be bound by the terms and
                    conditions,
                    do not use or access our services.</p>

                <p class="terms-and-conditions__section-header">2020source Services</p>

                <p>The site provides you with access to 2020source's tools and services. You understand and agree that
                    2020source,
                    at its discretion, at any time and without notice, reserves the right to modify, enhance or supplement the
                    services. You further understand and agree that any such changes will automatically constitute part of the
                    services and as such, will also be subject to this agreement, unless expressly stated otherwise. You
                    understand
                    and agree that 2020source, at its discretion, at any time and without notice, reserves the right to
                    discontinue
                    the site and/or the services, including without limitation access to any content, products or services
                    offered
                    in connection therewith, in whole or in part, temporarily or permanently. 2020source takes no responsibility
                    and
                    assumes no liability to you or any third party for any such modification or discontinuation.</p>

                <p class="terms-and-conditions__section-header">Obligation for Actions Taken Using Your Password</p>

                <p>All content or instructions transmitted by or received from anyone presenting your password on the site will
                    be
                    deemed binding on you. You agree that you are solely liable for all actions taken via your password, whether
                    or
                    not made with your knowledge or authority. You agree to guard your password carefully, with the full
                    awareness
                    that a failure to keep it secure will enable others to engage in transactions through the site for which you
                    will be legally responsible. If you suspect that someone who is not intended to have authority to act on
                    your
                    behalf may have obtained access to your password, please contact 2020source immediately to authorize
                    2020source
                    to deny access to the Site to anyone else presenting your password.</p>

                <p class="terms-and-conditions__section-header">Website Use Restrictions</p>

                <p>You agree not to use any data mining, robots, scraping or other automatic device, program, algorithm or
                    technology that operates as an agent for a user or another program or simulates human activity to use, access,
                    copy, acquire information, generate impressions, input information, store information, search or monitor any
                    portion of this website.</p>

                <p class="terms-and-conditions__section-header">Limitation of Liability</p>

                <p>Regardless of the type of claim or the nature of the cause of action, you agree that in no event will
                    2020source,
                    its affiliates, contractors, service providers, employees, agents or licensors or any other party involved
                    in
                    creating, producing or delivering the site or services ("2020source affiliates") be liable in any manner
                    whatsoever; (I) for any decision made or action taken or omitted by you in reliance upon the information
                    provided through the site; (II) for loss or inaccuracy of data or, cost of procurement of substitute goods,
                    services or technology; (III) for unauthorized access to or alterations of your third party content or other
                    information; and (IV) for any indirect, special, incidental, consequential, or punitive damages, including
                    but
                    not limited to loss for revenues and loss of profits, even if 2020source has been advised of the possibility
                    of
                    such damages. The aggregate liability of 2020source and the 2020source affiliates arising from or relating
                    to
                    this agreement (regardless of the form of action or claim) is limited to the amounts paid by you to
                    2020source
                    during the preceding (12) months. Some jurisdictions do not allow the exclusion of liability for incidental
                    or
                    consequential damages. Therefore, depending on the jurisdiction, some of the exclusions set forth in this
                    section may not apply to you.</p>

                <p class="terms-and-conditions__section-header">Control of Your Password</p>

                <p>You are responsible for all actions taken with your User ID and password, including fees (if applicable)
                    charged
                    to your account. Therefore we do not recommend that you disclose your 2020source User ID or password to any
                    third parties. If you choose to share your User ID and password or your Personal Information with third
                    parties,
                    you are responsible for all actions taken with your account and may be subject to legally binding actions
                    taken
                    on your behalf. Therefore, if your password has been compromised for any reason, you should immediately
                    change
                    your password as detailed in the Accessing, Reviewing, and Changing Your Information section.</p>

                <p class="terms-and-conditions__section-header">Accessing, Reviewing, and Changing Your Information</p>

                <p>We offer you the ability to automatically review and change the information you submit to us, including your
                    password, by logging into the Site and entering in the new information yourself or, where prompted, by
                    contacting 2020source Customer Care. You must promptly update your personal information if it changes or is
                    inaccurate.</p>

                <p class="terms-and-conditions__section-header">Other Information Collectors</p>

                <p>Except as otherwise expressly included in this privacy policy, this document only addresses the use and
                    disclosure of information we collect from you. To the extent that you disclose your information to other
                    parties, whether they are External Providers on the Site or other sites accessed via www.2020source.com,
                    different rules or policies apply to their use or disclosure of the information you disclose to them. Since
                    2020source does not control the privacy policies of third parties, you are responsible for understanding the
                    privacy policies of these third parties. 2020source is not responsible for the privacy policies of these
                    websites. We encourage you to ask questions before you disclose your Personal Information to others.</p>

                <p class="terms-and-conditions__section-header">Security</p>

                <p>All users must have a User ID and password issued at the time of registration on 2020source. Your information
                    is
                    stored on 2020source's servers located in the United States. We use procedural and technical safeguards to
                    protect your personal information against loss or theft as well as unauthorized access and disclosure to
                    protect
                    your privacy, including encryption, "firewalls," and Secure Socket Layer security. However, "perfect
                    security"
                    does not exist on the Internet.</p>

                <p class="terms-and-conditions__section-header">Notice</p>

                <p>We may amend this privacy policy at any time by posting the amended terms on the Site. If any material
                    changes
                    are made to this privacy policy, we will notify you by posting an announcement. By using the Site after such
                    amendment, you agree to the terms of the amended privacy policy.</p>

                <p class="terms-and-conditions__section-header">Financial Relationships With 2020source Affiliates (Stark and
                    Anti-kickback Law Compliance)</p>

                <p>2020source does not participate in or facilitate unlawful relationships under federal or state health care
                    laws.
                    If you have a financial relationship with any 2020source Affiliate, your relationship may cause your
                    requests
                    through 2020source to be prohibited referrals under the federal physician self-referral statute (commonly
                    referred to as the "Stark Law") or its state law analogs. Likewise, if you solicit, receive, offer or pay
                    remuneration, in cash or in kind, in return for the referral of patients, your activities may violate
                    federal or
                    state anti-kickback laws. We presume that you will consult with your counsel to avoid any violation of the
                    Stark
                    Law, its state analogs and anti-kickback laws. If 2020source learns that you have a relationship or are
                    engaging
                    in activities that violate any of these laws, 2020source will terminate your ability to access 2020source's
                    site.</p>

                <p class="terms-and-conditions__section-header">Third-Party Sites, Products and Services</p>

                <p>2020source may link to various external Web sites that are not under the control of 2020source, and
                    2020source is
                    not responsible for the content, products or services sold by any linked site or any link contained in a
                    linked
                    site. 2020source does not assume any responsibility or liability for the actions, products or content of all
                    these and any other third parties. 2020source reserves the right to terminate any link or linking program at
                    any
                    time. If you decide to access any of the third-party sites linked to this site, you do this entirely at your
                    own
                    risk.</p>

                <p class="terms-and-conditions__section-header">Copyright Notice</p>

                <p>Copyright © <span current-year></span>, All content on this site, such as text, graphics, images, and logos,
                    is the property of Vision
                    Service Plan or its content suppliers and business affiliates and is protected by United States copyright
                    laws.</p>

                <p class="terms-and-conditions__section-header">Fee and Payment Policies (if applicable)</p>

                <p>For questions related to billing, email apquestions@VSP.com.</p>

                <p>You agree to pay all fees which arise out of your use of the site or the services in accordance with the
                    terms
                    posted on the site and the terms of any other written agreement with 2020source. We may change our fees and
                    payment policies from time to time and will post any such changes on our web site or by written notice.</p>

                <p>For VSP panel providers, after non-payment of claim fees or other site service fees after 60 days, 2020source
                    reserves the right to withhold the amount from the bi-monthly VSP payment of services check. A service fee
                    may
                    apply. For providers or opticians outside the VSP panel, after non-payment of claim fees or other site
                    service
                    fees for 60 days, 2020source will charge the credit card on file for the delinquent account for all payments
                    due
                    2020source, including any finance charges assessed. If a valid credit card is not on file with 2020source,
                    services may be interrupted and the account may be forwarded to a third party collection agency.</p>

                <p>The customer will be responsible for all bank charges resulting from checks returned for non-sufficient
                    funds.
                    2020source will refund credit balances upon request.</p>

                <p>Annual fees for enrollment/membership (if applicable) are payable via credit card at the time 2020source
                    notifies
                    the user of approval and provides a site access code and password. 2020source's money-back guarantee can be
                    redeemed within the first 60 calendar days beginning on the day the account ID and password are provided.
                    Requests can be made either by phone or mail contact with 2020source's Customer Care department and must
                    include
                    the site access ID and password. A credit for the full annual fee will be made within 30 days to the
                    original
                    credit card used for registration. Renewal of this annual fee is billed upon 12 months of the provision of
                    the
                    access code/password. Non-payment of this annual fee after 30 days will result in the termination of the
                    user's
                    account, and 2020source is not responsible for the loss of any account history or return of transactions or
                    data
                    provided.</p>

                <p>Any questions or comments regarding the site should be directed to apquestions@VSP.com.</p>
        </div>


        </div>

    </div>
</div>
